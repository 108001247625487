import { useEffect, useState } from "react";
import { Box, Grid, Typography, Snackbar, Container, Switch } from "@material-ui/core";
import { TitleIcon, CardElevation, ButtonDegrade } from '@findep/mf-landings-core';
// import FelicidadesApp from "./elements/FelicidadesApp.compilable";
import { css } from '@emotion/react';
import { navigate } from '../helpers/queryNavigate';
import GetAppIcon from '@material-ui/icons/GetApp';
// import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import { VerificacionKnox } from "../services/LandingV4";
import Alert from '@material-ui/lab/Alert';

const DescargarApplicacion = ({ pageContext }) => {

    const id = !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '';
    const { creditApplicationId } = id;
    const [active, setActive] = useState(false);
    const [switchActive, setSwitchActive] = useState(false)
    const handleCheck = (event) => {
        setActive(!active)
    }

    // const [qr, setQr] = useState('');
    const [errorservice, setErrorservice] = useState(false);

    // useEffect(() => {

    //     let navigateUrl = pageContext?.next?.path
    //     console.log("navigateUrl=> ", navigateUrl)
    //     //navigate(navigateUrl)
    //     // const verifyKnox = new VerificacionKnox(creditApplicationId);
    //     // verifyKnox.send()
    //     //     .then((response) => {
    //     //         console.log("ok=> ", response)
    //     //     }
    //     //     )
    //     //     .catch(error => {
    //     //         setErrorservice(true);
    //     //         console.error("error=> ", error);
    //     //     })
    // }, [creditApplicationId]);

    // const { company } = pageContext;

    const paddingGridCss = css`
        padding: 1rem;
    `

    const centerElementCss = css`
        text-align: center;
    `
    const paragraphCss = css`
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.1rem;
    `
    const numberCss = css`
        color: rgba(0, 0, 0, 0.87);
        font-size: 3.1rem;
    `
    const imgSizeCss = css`
        width: 65%;
    `

    const nextStep = () => {
        let navigateUrl = pageContext?.next?.path
        const verifyKnox = new VerificacionKnox();
        verifyKnox.send(creditApplicationId)
            .then((response) => {
                console.log("okResponse=> ", response)
            }
            )
            .catch(error => {
                setErrorservice(true);
                console.error("failService=> ", error);
            })
        navigate(navigateUrl);
    }

    return (
        <Container justify="center">

            <Grid container justify="center">
                <Grid item css={centerElementCss}>
                    <TitleIcon icon={GetAppIcon} title="Descarga la Aplicación" textColor />
                </Grid>
            </Grid>

            <Grid container justify="center" >
                <Grid item xs={12} md={6} css={paddingGridCss}>
                    <CardElevation>
                        <Typography css={paragraphCss} >
                            Para ayudarte a mejorar tu historial crediticio y continuar con tu proceso, es necesario que descargues
                            la APP Nuovo y registres tu smartphone Samsung, el cual en caso que presentes un atraso en el cumplimiento
                            de tus pagos, será bloqueado hasta que nuevamente tu crédito esté al corriente.
                        </Typography>
                        {/* <FelicidadesApp company={company} /> */}
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <a style={{ width: '60%' }} href="https://play.google.com/store/apps/details?id=com.promobitech.nuovo" target="_blank" rel="noopener noreferrer">
                                <img alt="Disponible en Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png" width="100%" />
                            </a>
                        </Box>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <br />
                            <Typography css={paragraphCss}>
                                <a style={{ width: '60%' }} href="https://docs.google.com/document/d/19Tlme7Pyh2jb4AsWsul9iCvagNNv_oWzbw" target="_blank" rel="noopener noreferrer">
                                    Manual de instalación
                                </a>
                            </Typography>
                            <br />
                            <br />
                            <Typography css={paragraphCss} >
                                Código de enrolamiento.
                            </Typography>

                            <Typography css={numberCss} >
                                86E2F1
                            </Typography>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                <Switch
                                    id="carga-archivo-switch"
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={handleCheck}
                                    color="primary"
                                    checked={active}
                                    disabled={switchActive}
                                />
                                <span style={{ fontWeight: 600 }}>
                                    Ya instalé la aplicación
                                </span>

                            </Grid>
                            <br />
                            <ButtonDegrade
                                id="descargar-aplicacion-button"
                                textButton="TERMINAR Y CERRAR"
                                //icon={CameraEnhanceIcon}
                                onClick={nextStep}
                                disabled={!active}
                            />
                        </Grid>
                    </CardElevation>
                </Grid>
            </Grid>

            <Snackbar open={errorservice} autoHideDuration={6000} onClose={() => setErrorservice(false)}>
                <Alert variant="filled" severity="warning" onClose={() => setErrorservice(false)}>
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                </Alert>
            </Snackbar>
        </Container>

    );
}

export default DescargarApplicacion;